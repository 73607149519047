/* eslint-disable prettier/prettier */
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, type Dispatch, type SetStateAction } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";

import { useLocale } from "@calcom/lib/hooks/useLocale";
import { localStorage } from "@calcom/lib/webstorage";
import { trpc } from "@calcom/trpc/react";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  EmailField,
  DialogHeader,
  showToast,
} from "@calcom/ui";
import { Newspaper, AlertTriangle } from "@calcom/ui/components/icon";

interface IGenericDialog {
  isOpenDialog: boolean;
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>;
  //   bookingId: number;
  //   paymentAmount: number;
  //   paymentCurrency: string;
}

export const WaitingList = (props: IGenericDialog) => {
  const { t } = useLocale();
  //const utils = trpc.useContext();
  const formSchema = z.object({
    email: z
      .string()
      .min(1, `${t("error_required_field")}`)
      .email(`${t("enter_valid_email")}`),
  });
  const handleWaitingList = () => {
    localStorage.setItem("noDisplayWaitingList", "true");
    setIsOpenDialog(false);
  };
  const { isOpenDialog, setIsOpenDialog } = props;
  const methods = useForm<{ email: string }>({
    resolver: zodResolver(formSchema),
  });
  const { register, formState } = methods;
  const [chargeError, setChargeError] = useState(false);
  const SubscribeToWaitingList = trpc.viewer.public.waitingList.useMutation({
    onSuccess: () => {
      localStorage.setItem("noDisplayWaitingList", "true");
      showToast("Charge successful", "success");
      setIsOpenDialog(false);
    },
    onError: () => {
      setChargeError(true);
    },
  });
  const onSubmit = async ({ email }: { email: string }) => {
    SubscribeToWaitingList.mutate({
      email,
    });
  };

  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogContent>
        <div className="flex flex-row space-x-3">
          <div className="flex h-10 w-10 flex-shrink-0 items-end justify-center rounded-full">
            <Newspaper />
          </div>
          <div className="pt-1">
            <DialogHeader title={t("join_the_waiting_list_preview_access")} />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate data-testid="login-form">
                <EmailField t={t} required {...register("email")} />
                <Button
                  type="submit"
                  data-testid="send_request"
                  color="primary"
                  disabled={formState.isSubmitting || chargeError}
                  className="w-full justify-center">
                  {t("register")}
                </Button>
              </form>
            </FormProvider>
            {chargeError && (
              <div className="mt-4 flex text-red-500">
                <AlertTriangle className="mr-2 h-5 w-5 " aria-hidden="true" />
                <p className="text-sm">{t("error_charging_card")}</p>
              </div>
            )}
            <DialogFooter>
              <DialogClose onClick={handleWaitingList}>{t("not_interested")}</DialogClose>
            </DialogFooter>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
