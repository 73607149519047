import { ScrollableArea } from "@calcom/ui";
import { useLocale } from "@calcom/lib/hooks/useLocale";

const data = {
  title: "terms_and_conditions",
  description:
    'terms_and_conditions_description',
  items: [
    {
      title: "use_of_timetokens",
      description: [
        `timetokens_cond`,
        `booking_calendar`,
        `cancellations`,
      ],
    },
    {
      title: "ai_clones",
      description: [
        "ai_clones_desc",
        "disclaimer",
      ],
    },
    {
      title: "expertise_presentation_page",
      description: [
        "expertise_presentation_page_desc",
        "microcoard",
      ],
    },
    {
      title: "user_responsibilities",
      description: [
        "accuracy_of_information",
        "compliance_with_laws",
      ],
    },
    {
      title: "limitation_of_liability",
      description: [
        "service_provided",
        "service_not_responsible",
      ],
    },
    {
      title: "termination",
      description: [
        "termination_desc",
      ],
    },
    {
      title: "changes_to_terms",
      description: [
        "update_terms",
      ],
    },
    {
      title: "miscellaneous",
      description: [
        "terms_constitute",
        "provision",
      ],
    },
  ],
  footer: [
    "By using the Service, you acknowledge that you have read and understood these Terms and agree to abide by them. If you do not agree with these Terms, please do not use the Service.",

    "If you have any questions or concerns regarding these Terms, please contact us at mailto:support@mygpt.fi",
  ],
};

const TermsAndConditions = () => {
  const { t } = useLocale();
  return (
    <ScrollableArea className="mx-0 my-3 !h-[650px] md:mx-10 md:my-12">
      <div className="text-secondary flex flex-col gap-4">
        <p className="text-center text-xl font-bold">{t(data.title)}</p>
        <p>{t(data.description)}</p>
        <div className="flex flex-col gap-2">
          {data.items.map((item, key) => (
            <div key={key} className="flex flex-col gap-1">
              <p className="text-lg font-semibold">{t(item.title)}</p>
              {item.description.map((el, id) => (
                <p key={id}>&nbsp;&nbsp;&nbsp;&nbsp;{t(el)}</p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </ScrollableArea>
  );
};

export default TermsAndConditions;
