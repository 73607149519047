import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import React, { useCallback, useEffect, useState } from "react";

import classNames from "@calcom/lib/classNames";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import type { SVGComponent } from "@calcom/types/SVGComponent";

import type { ButtonProps } from "../../components/button";
import { Button } from "../../components/button";

export type DialogProps = React.ComponentProps<(typeof DialogPrimitive)["Root"]> & {
  name?: string;
  clearQueryParamsOnClose?: string[];
};
export function Dialog(props: DialogProps) {
  const router = useRouter();
  const { children, name, ...dialogProps } = props;

  // Initialized based on dialogProps.open to maintain the controlled component pattern.
  // Added a type assertion to ensure dialogProps.open is treated as a boolean during initialization.
  const [isOpen, setIsOpen] = useState<boolean>(!!dialogProps.open);

  useEffect(() => {
    // If dialogProps.open changes externally, update the local isOpen state.
    // Added a check for undefined before calling setIsOpen.
    if (dialogProps.open !== undefined) {
      setIsOpen(dialogProps.open);
    }
  }, [dialogProps.open]);

  const handleOpenChange = useCallback(
    (newOpenValue: boolean) => {
      // Added the boolean type for newOpenValue parameter
      if (props.onOpenChange) {
        props.onOpenChange(newOpenValue);
      }
      setIsOpen(newOpenValue); // Set the open state based on the new value.

      // Managing URL query parameters for dialog state if needed.
      const query = { ...router.query };
      if (newOpenValue) {
        query["dialog"] = name;
      } else {
        delete query["dialog"];
      }
      router.push({ pathname: router.pathname, query }, undefined, { shallow: true });
    },
    [props, router, name]
  );

  // Ensure that the dialogProps passed down include the controlled open state and the handler.
  return (
    <DialogPrimitive.Root {...dialogProps} open={isOpen} onOpenChange={handleOpenChange}>
      {children}
    </DialogPrimitive.Root>
  );
}

type DialogContentProps = React.ComponentProps<(typeof DialogPrimitive)["Content"]> & {
  size?: "xl" | "lg" | "md";
  type?: "creation" | "confirmation";
  title?: string;
  description?: string | JSX.Element | null;
  closeText?: string;
  actionDisabled?: boolean;
  Icon?: SVGComponent;
  HeaderIcon?: SVGComponent | React.ElementType;
  enableOverflow?: boolean;
};

// enableOverflow:- use this prop whenever content inside DialogContent could overflow and require scrollbar
export const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  ({ children, title, Icon, enableOverflow, type = "creation", ...props }, forwardedRef) => {
    return (
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="fadeIn fixed inset-0 z-50 bg-neutral-800 bg-opacity-70 transition-opacity dark:bg-opacity-70 " />
        <DialogPrimitive.Content
          {...props}
          className={classNames(
            "fadeIn bg-default fixed left-1/2 top-1/2 z-50 w-full max-w-[22rem] -translate-x-1/2 -translate-y-1/2 rounded-md text-left shadow-xl focus-visible:outline-none sm:align-middle",
            props.size == "xl"
              ? "p-8 sm:max-w-[90rem]"
              : props.size == "lg"
              ? "p-8 sm:max-w-[70rem]"
              : props.size == "md"
              ? "p-8 sm:max-w-[48rem]"
              : "p-8 sm:max-w-[35rem]",
            "max-h-[95vh]",
            enableOverflow ? "overflow-auto" : "overflow-visible",
            `${props.className || ""}`
          )}
          ref={forwardedRef}>
          {type === "creation" && (
            <div>
              <div className="flex justify-between">
                <DialogHeader title={title} subtitle={props.description} HeaderIcon={props.HeaderIcon} />
                {Icon && (
                  <div className="mr-4 inline-flex h-5 w-5 items-center justify-center rounded-full">
                    <DialogClose className="p-0">
                      <Icon
                        className="text-emphasis  hover:border-pink h-8 w-8 hover:rounded-md hover:border"
                        color="#5d2782"
                      />
                    </DialogClose>
                  </div>
                )}
              </div>
              <div className="flex flex-col">{children}</div>
            </div>
          )}
          {type === "confirmation" && (
            <div className="flex">
              {Icon && (
                <div className="bg-emphasis mr-4 inline-flex h-10 w-10 items-center justify-center rounded-full">
                  <Icon
                    className="text-emphasis hover:border-pink h-4 w-4 hover:rounded-md hover:border"
                    color="#5d2782"
                  />
                </div>
              )}
              <div className="w-full">
                <DialogHeader title={title} subtitle={props.description} />
                <div className="flex  space-y-6">{children}</div>
              </div>
            </div>
          )}
          {!type && children}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    );
  }
);

type DialogHeaderProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  HeaderIcon?: SVGComponent | React.ElementType;
};

export function DialogHeader(props: DialogHeaderProps) {
  const { HeaderIcon } = props;
  // if (!props.title ) return null;
  const content = HeaderIcon ? (
    <div className="mb-4 flex  min-w-[100%]  justify-center ">
      <HeaderIcon className="h-[36px] w-[36px]" />
    </div>
  ) : (
    <div className="mb-4">
      <h3 className="leading-20 text-semibold font-cal text-emphasis pb-1 pt-2 text-xl" id="modal-title">
        {props.title}
      </h3>
      {props.subtitle && <div className="text-subtle text-sm">{props.subtitle}</div>}
    </div>
  );
  return content;
}

export function DialogFooter(props: {
  children: ReactNode;
  className?: string;
  showDivider?: boolean;
  customDividerClassNames?: string;
}) {
  return (
    <div className={classNames("bg-default", props.className)}>
      {props.showDivider && (
        <hr className={classNames("border-subtle absolute right-0 w-full", props.customDividerClassNames)} />
      )}
      <div
        className={classNames(
          "flex justify-end space-x-2 pt-4 rtl:space-x-reverse",
          props.showDivider && "-mb-4"
        )}>
        {props.children}
      </div>
    </div>
  );
}

DialogContent.displayName = "DialogContent";

export const DialogTrigger = DialogPrimitive.Trigger;
// export const DialogClose = DialogPrimitive.Close;

export function DialogClose(
  props: {
    dialogCloseProps?: React.ComponentProps<(typeof DialogPrimitive)["Close"]>;
    children?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    color?: ButtonProps["color"];
  } & React.ComponentProps<typeof Button>
) {
  const { t } = useLocale();
  return (
    <DialogPrimitive.Close asChild {...props.dialogCloseProps}>
      {/* This will require the i18n string passed in */}
      <Button color={props.color || "minimal"} {...props}>
        {props.children ? props.children : t("Close")}
      </Button>
    </DialogPrimitive.Close>
  );
}
