import classNames from "classnames";
import { CornerDownRight } from "lucide-react";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";

import { useLocale } from "@calcom/lib/hooks/useLocale";
import { ScrollableArea } from "@calcom/ui";

type benifitType = {
  id: number;
  name: string;
  subBenefits: string[];
};

// t("share_your_availabilities"): "Share your availabilities",
// "create_your_second_brain": "Create your second brain",
// "grow_your_buisness_network": "Grow your business network"
let mainBenefits = [
  {
    id: 1,
    name: "share_your_availabilities",
    subBenefits: [
      "calendar_connection",
      "meeting_organization",
      "session_creation",
      "booking_link_sharing",
      "skills_description",
      "team_description_and_meetings",
    ],
  },
  {
    id: 2,
    name: "create_an_ai_clone",
    subBenefits: [
      "second_brain_training",
      "exact_expertise_description",
      "ai_strategic_ally_description",
      "conversation_engagement",
    ],
  },
  {
    id: 3,
    name: "find_new_customers_ai",
    subBenefits: [
      "identify_potential_clients",
      "evaluate_suppliers",
      "discover_investors",
      "assess_financial_stability",
      "never_lose_opportunity",
    ],
  },
];

const members = [
  {
    alt: "member1",
    id: 1,
  },
  {
    alt: "member2",
    id: 2,
  },
  {
    alt: "member3",
    id: 3,
  },
  {
    alt: "member4",
    id: 4,
  },
  {
    alt: "member5",
    id: 5,
  },
  {
    alt: "member6",
    id: 6,
  },
];
export default function Benefits() {
  const { t } = useLocale();
  const [benifitSelected, setBenifit] = useState<number>(1);
  const [subBenifit, setSubBenefits] = useState<string[] | null>(null);

  const handleSetBenifit = (item: benifitType) => {
    if (benifitSelected === item.id) {
      return;
    }
    setBenifit(item.id);
    setSubBenefits(item.subBenefits);
  };
  const scrollableArea = useRef<HTMLDivElement>(null);
  let reachedBottom = false;
  let reachedTop = false;
  useEffect(() => {
    const observeBottomHandler = (ob: any) => {
      reachedBottom = false;
      ob.forEach((el: any) => {
        if (el?.isIntersecting) {
          reachedBottom = true;
        } else {
          reachedBottom = false;
        }
      });
    };
    const observeTopHandler = (ob: any) => {
      reachedTop = false;
      ob.forEach((el: any) => {
        if (el?.isIntersecting) {
          reachedTop = true;
        } else {
          reachedTop = false;
        }
      });
    };
    const buttomObserver = new IntersectionObserver(observeBottomHandler);
    const topObserver = new IntersectionObserver(observeTopHandler);

    const bottomEl = document.querySelectorAll("[data-observe-bottom]");

    bottomEl.forEach((el: any) => {
      buttomObserver.observe(el);
    });

    const topEl = document.querySelectorAll("[data-observe-top]");

    topEl.forEach((el: any) => {
      topObserver.observe(el);
    });

    const handelScrollEvent = (e: any) => {
      if (e.deltaY < 0) {
        if (benifitSelected <= 3 && benifitSelected !== 1 && reachedTop) {
          setBenifit(benifitSelected - 1);
        } else {
          setBenifit(3);
        }
      } else if (e.deltaY > 0) {
        if (benifitSelected >= 1 && benifitSelected !== 3 && reachedBottom) {
          setBenifit(benifitSelected + 1);
        } else if (benifitSelected >= 1 && !reachedBottom) {
        } else {
          setBenifit(1);
        }
      }
    };
    scrollableArea.current?.addEventListener("wheel", handelScrollEvent);

    return () => {
      scrollableArea.current?.removeEventListener("wheel", handelScrollEvent);
    };
  });

  useEffect(() => {
    const selectedBenifit: benifitType[] = mainBenefits.filter((item) => item.id === benifitSelected);
    const valueToBeSet =
      selectedBenifit[0].subBenefits !== undefined && selectedBenifit[0].subBenefits.length > 0
        ? selectedBenifit[0].subBenefits
        : null;
    setSubBenefits(valueToBeSet);
    if (scrollableArea.current) {
      console.log(scrollableArea.current.children[0].scrollTop);
      scrollableArea.current.children[0].scrollTop = 0;
      reachedBottom = false;
      reachedTop = false;
    }
  }, [benifitSelected]);
  return (
    <div className="grid grid-cols-1 md:h-[650px] md:grid-cols-3">
      <div className="col-span-1 mx-4 flex">
        <div className="flex h-full flex-col justify-center md:mr-4 md:px-2 ">
          <div className="flex-row">
            <span className="text-secondary font-sans text-2xl font-semibold md:text-3xl">
              {t("what_benifit_will_you_get")}
            </span>
          </div>
          <div className=" my-4 flex flex-row">
            <div className="flex-col">
              {mainBenefits.map((benifit) => {
                return (
                  <div
                    key={benifit.id}
                    className={classNames(
                      benifit.id === benifitSelected && "text-secondary font-semibold",
                      "text-secondary cursor-pointer py-3 font-light"
                    )}
                    onClick={() => handleSetBenifit(benifit)}>
                    <div className="flex gap-2">
                      {benifit.id === benifitSelected ? <CornerDownRight /> : null} {t(benifit.name)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="relative mx-3 flex h-full flex-col items-center justify-center">
              <div className=" bg-emphasis h-[90%] w-1" />
              <div
                className={classNames(
                  "bg-pink absolute z-50 mx-auto h-5 w-3 rounded-md shadow",
                  benifitSelected === 1 && "top-0",
                  benifitSelected === 2 && "top-[45%]",
                  benifitSelected === 3 && "top-[86%]"
                )}>
                {null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-2 flex flex-col justify-center px-3">
        <div className="flex flex-row justify-center">
          <div className="flex  md:-space-x-6">
            {members.map((member) => {
              return (
                <Image
                  key={member.id}
                  src={"/app-members/" + member.id + ".svg"}
                  width={90}
                  height={90}
                  alt={member.alt}
                  className="inline-block !h-full !w-full !overflow-hidden rounded-full ring-4 ring-[#E9E2EF] md:ring-8 md:ring-[#F0EFF4]"
                />
              );
            })}
          </div>
        </div>
        <div className="mt-6 flex !h-5/6 flex-row">
          <div className="flex flex-col" ref={scrollableArea}>
            <ScrollableArea className="h-[200px] bg-transparent md:h-full">
              <div data-observe-top>
                <></>
              </div>
              {subBenifit && subBenifit !== undefined && subBenifit.length > 0
                ? subBenifit.map((item, index) => {
                    return (
                      <li key={index} className="text-secondary flex-row py-2">
                        {t(item)}
                      </li>
                    );
                  })
                : null}
              <div data-observe-bottom>
                <></>
              </div>
            </ScrollableArea>
          </div>
        </div>
      </div>
    </div>
  );
}
