import classNames from "classnames";
import { sheets } from "googleapis/build/src/apis/sheets";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useState } from "react";

import { useFlagMap } from "@calcom/features/flags/context/provider";
import { SUBSCRIPTION_PRICE } from "@calcom/lib/constants";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import { Button, Dialog, HeadSeo, DialogContent, DialogTrigger, ScrollableArea } from "@calcom/ui";
import { LogOut, Menu, X } from "@calcom/ui/components/icon";

import Loader from "@components/Loader";
import Footer from "@components/auth/Footer";
import type { LinkProps } from "@components/auth/Footer";
import { WaitingList } from "@components/dialog";
import MicroCards from "@components/microcard";
import PriceInfo from "@components/price-info";
import PriceListItem from "@components/prices/PriceListItem";
import CarouselAvatarComponentN from "@components/ui/CarouselAvatarsComponentN";

// import CarouselAvatars from "@components/ui/CarouselAvatars";
// import CarouselAvatarsComponent from "@components/ui/CarouselAvatarsComponent";
import { IS_GOOGLE_LOGIN_ENABLED, IS_SAML_LOGIN_ENABLED } from "@server/lib/constants";

interface Props {
  title: string;
  description: string;
  footerText?: React.ReactNode | string;
  showLogo?: boolean;
  heading?: string;
  hideFooter?: boolean;
  loading?: boolean;
}

export const footerLinks: LinkProps[] = [
  {
    name: "Benefits",
    url: "/",
    type: "modal",
    col: 3,
  },
  {
    name: "Technology",
    url: "/",
    type: "modal",
    col: 3,
  },
  {
    name: "Terms and conditions",
    url: "/",
    type: "modal",
    col: 6,
  },
];

const members = [
  {
    alt: "member1",
    id: 1,
  },
  {
    alt: "member2",
    id: 2,
  },
  {
    alt: "member3",
    id: 3,
  },
  {
    alt: "member4",
    id: 4,
  },
  {
    alt: "member5",
    id: 5,
  },
];

const pricesList = [
  {
    name: "Free",
    features: ["your_expert_profile", "your_microcard", "your_online_calendar"],
    ipDevice: "",
    password: "",
  },
  {
    name: `€${SUBSCRIPTION_PRICE.LEVEL1.EUR}/month`,
    features: [
      "optimizes_expert_keywords",
      "connect_calendar_into_mygpt",
      "manage_experts",
      "meetings_payment",
      "lifetime_subscription",
    ],
    ipDevice: "",
    password: "",
  },
  {
    name: `€${SUBSCRIPTION_PRICE.LEVEL2.EUR}/month`,
    features: ["prolink", "clone_training", "expert_dashboard"],
    ipDevice: "",
    password: "",
  },
  {
    name: `€${SUBSCRIPTION_PRICE.LEVEL3.EUR}/month`,
    features: ["mixtral_ai", "limit_of_10_gb_for_life", "ai_brain_for_life"],
    ipDevice: "",
    password: "",
  },
];

export default function AuthContainer(props: React.PropsWithChildren<Props>) {
  const router = useRouter();
  const { t } = useLocale();
  const [toggleFlag, setToggleFlag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showWaitingList, setShowWaitingList] = useState(false);
  const handleToggleNav = () => {
    setToggleFlag(!toggleFlag);
  };
  const pathName = usePathname();
  const flag = useFlagMap();
  const [priceLevel, setPriceLevel] = useState(0);

  return (
    <div className="to-darkemphasis bg-auth bg-gradient-to-b from-gray-100">
      {toggleFlag ? (
        <div className="z-50 !h-screen !w-full bg-white py-4 transition delay-150 ease-in-out">
          <div className="mb-auto flex justify-between ">
            <div className="ms-6 flex-col">
              {props.showLogo && (
                <Image src="/my-gpt-logo.svg" width={130} height={20} className="left-0" alt="logo" />
              )}
            </div>
            <div className="text-secondary flex-col">
              <div className="flex flex-row gap-8">
                <div className="flex-col ">
                  <div className="flex flex-row gap-1">
                    <Button
                      onClick={() => handleToggleNav()}
                      StartIcon={X}
                      variant="icon"
                      size="lg"
                      color="minimal"
                      className="!p-none border-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="align-center flex h-full flex-row justify-center ">
            <div className="flex flex-col self-center">
              <div className="text-secondary flex flex-col gap-8">
                <Dialog open={isOpen} onOpenChange={setIsOpen}>
                  <DialogTrigger asChild>
                    <Button
                      variant="icon"
                      size="lg"
                      color="secondary"
                      onClick={() => setIsOpen(true)}
                      className="p-none text-secondary mr-1 h-10 w-12 self-center border-0 bg-transparent text-xl">
                      Prices
                    </Button>
                  </DialogTrigger>
                  <DialogContent
                    className="to-emphasis flex flex-row bg-gradient-to-b from-gray-100"
                    size="xl"
                    Icon={X}
                    title={t("")}>
                    <div className="mt-5 flex-row ">
                      <ScrollableArea className="grid h-[600px] gap-5  sm:grid-cols-1 md:h-full md:grid-cols-4">
                        {pricesList.map((priceItem, index) => {
                          return (
                            <PriceListItem
                              key={index}
                              priceItem={priceItem}
                              disabled={index === 4}
                              handleClick={() => {
                                window.localStorage.setItem("price-type", `${index}`);
                                setIsOpen(false);
                                setPriceLevel(index);
                              }}
                            />
                          );
                        })}
                      </ScrollableArea>
                    </div>
                  </DialogContent>
                </Dialog>
                <div className="flex flex-row ">
                  <div className="flex flex-row gap-1">
                    <LogOut className="h-12 w-10 flex-col" />
                    <div className="flex flex-col">
                      <Link
                        onClick={() => handleToggleNav()}
                        href="/auth/login"
                        className="text-md z-50 flex-row">
                        {t("sign_in")}
                      </Link>
                      <Link
                        onClick={() => {
                          handleToggleNav();
                          window.localStorage.setItem("price-type", "0");
                          setPriceLevel(0);
                        }}
                        href="/signup"
                        className="text-md z-50 flex-row">
                        {t("sign_up")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <div className="bg-[url('/imgpsh_fullsize_anim.png')] bg-cover bg-no-repeat md:grid lg:!max-h-screen lg:grid-rows-[_80px_1fr_1fr_1fr_1fr_1fr_0px]">
        <div className="relative min-h-[500px] md:grid lg:!max-h-screen lg:grid-rows-[_80px_1fr_1fr_1fr_1fr_1fr_0px]">
          <div className="flex flex-row md:row-span-1">
            <div className="ms-6 flex flex-1 flex-col justify-center pt-4 sm:px-6 md:mx-6 lg:px-4">
              <HeadSeo title={props.title} description={props.description} />
              <div className="mb-auto flex justify-between ">
                <div className="flex-col">
                  {props.showLogo && (
                    <a href="/">
                      <Image src="/my-gpt-logo.svg" width={150} height={250} className="left-0" alt="logo" />
                    </a>
                  )}
                </div>
                <div className="text-secondary flex-col">
                  <div className="flex flex-row gap-8">
                    <Dialog open={isOpen} onOpenChange={setIsOpen}>
                      <DialogTrigger asChild>
                        <Button
                          variant="icon"
                          size="lg"
                          color="secondary"
                          aria-label={t("delete")}
                          onClick={() => setIsOpen(true)}
                          className="p-none text-secondary mr-1 hidden border-0 bg-transparent sm:inline">
                          Prices
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="to-emphasis" size="xl" Icon={X} title={t("")}>
                        <div className="mt-5 flex-row ">
                          <ScrollableArea className="grid h-[600px] gap-5  sm:grid-cols-1 md:h-full md:grid-cols-4">
                            {pricesList.map((priceItem, index) => {
                              return (
                                <PriceListItem
                                  key={index}
                                  priceItem={priceItem}
                                  disabled={index === 4}
                                  handleClick={() => {
                                    setIsOpen(false);
                                    window.localStorage.setItem("price-type", `${index}`);
                                    setPriceLevel(index);
                                    if (index === 2 || (index === 3 && flag?.waitinglist)) {
                                      setShowWaitingList(true);
                                    } else {
                                      router.push("/signup");
                                    }
                                  }}
                                />
                              );
                            })}
                          </ScrollableArea>
                        </div>
                      </DialogContent>
                    </Dialog>
                    <div className="hidden flex-col md:contents">
                      <div className="flex flex-row gap-1">
                        <LogOut className="h-8 w-6 flex-col" />
                        <div className="flex flex-col">
                          <Link href="/auth/login" className="flex-row text-xs">
                            {t("sign_in")}
                          </Link>
                          <Link
                            href="/signup"
                            className="flex-row text-xs"
                            onClick={() => {
                              window.localStorage.setItem("price-type", "0");
                              setPriceLevel(0);
                            }}>
                            {t("sign_up")}
                          </Link>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                    <div className="contents flex-col md:hidden">
                      <Button
                        onClick={() => handleToggleNav()}
                        StartIcon={Menu}
                        variant="icon"
                        size="lg"
                        color="minimal"
                        className="!p-none text-secondary  border-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:row-span-1 lg:grid-cols-[minmax(500px,_1fr)_1fr] lg:grid-rows-1">
            <div className="row-start-1 mx-auto h-fit lg:col-span-1 lg:row-span-1 lg:mx-4">
              <div
                className={classNames(
                  props.showLogo ? "" : "",
                  "flex-row sm:mx-2 sm:w-full sm:max-w-[100%] md:mt-14 md:flex-col"
                )}>
                {props.heading && (
                  <h2 className="text-secondary line-height-2 mx-6 mt-6 text-center font-sans text-lg font-medium leading-normal sm:max-w-md md:text-3xl lg:mx-4 lg:mt-0 lg:text-left">
                    {t("create_your_expert")}
                    <br />
                    <span className="text-base font-extralight md:text-2xl">
                      {t("create_your_expert_description")}
                    </span>
                  </h2>
                )}
                {props.loading && (
                  <div className="absolute z-50 flex h-screen w-full items-center ">
                    <Loader />
                  </div>
                )}
                <div className="mb-auto mt-8 sm:mx-1  sm:w-[100%] sm:max-w-lg  md:flex-col xl:w-[95%]">
                  <div className="mx-2 px-2 pt-5 sm:px-4 ">{props.children}</div>
                </div>
                <div
                  className="//
                  ${ //
                  IS_GOOGLE_LOGIN_ENABLED   && IS_SAML_LOGIN_ENABLED ? 'my-4' : 'my-14' //
                  } mx-auto
                  ">
                  {/* <CarouselDemo /> */}
                  <div className="flex flex-row sm:justify-center  lg:justify-normal">
                    <p className="text-secondary mx-3 my-8 h-20 break-words text-center text-sm font-medium sm:w-full sm:max-w-md  md:mt-5 lg:text-left">
                      {t("keyword_searches_are_over")} <br />
                      <span className="text-sm font-light">{t("keyword_searches_are_over_description")}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mx-2 flex h-fit flex-1 flex-col justify-center sm:px-6 lg:row-span-3 lg:mx-0 lg:w-[90%] lg:justify-start">
              <div className="mx-auto my-6 h-[60vh] flex-row md:my-0">
                <div className="flex h-full w-full items-center">
                  {pathName.includes("signup") && priceLevel > 0 && priceLevel < 4 ? (
                    <PriceInfo priceLevel={priceLevel} />
                  ) : (
                    <MicroCards />
                  )}
                </div>
              </div>
              <div className="mt-4 md:mx-auto md:my-4 lg:mt-2">
                <div className="mx-auto ">
                  {/* <CarouselAvatars /> */}
                  <CarouselAvatarComponentN />
                </div>
              </div>
              <div className="flew-row mx-auto my-4 justify-center font-sans font-medium text-gray-500 md:my-4 ">
                {t("experts_in_finance_marketing")}
              </div>
            </div>
          </div>
          {!props.hideFooter ? (
            <div className="order-last mt-auto flex flex-row pt-5">
              <Footer items={footerLinks} className="pt-4" authPage />
            </div>
          ) : null}
        </div>
      )}
      {showWaitingList && <WaitingList setIsOpenDialog={setShowWaitingList} isOpenDialog={showWaitingList} />}
    </div>
  );
}
